<template>
	<div class="detail qjc-fts-26 qjc-texta-l">
		<van-nav-bar
			title="收益明細"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<div class="notice">
			每月5號結算上個自然月(每月1日-每月最後一日)的收入，建議6號在進行提現
		</div>
		
		<div class="tit">
			<div class="date">日期</div>
			<div class="surplus">結餘</div>
		</div>
		
		<van-list
		  v-model="loading"
		  :finished="finished"
		  finished-text="沒有更多了"
		  @load="onLoad"
		>
		  <dl v-for="item in list" :key="item.month">
		  	<dt>{{ item.month }}</dt>
		  	<dd v-for="det in item.profitDetail" :key="det.day">
		  		<div class="date">{{ det.day }}</div>
		  		<ul class="cont">
		  			<li v-for="d in det.data" :key="d.name">
		  				<p :class="[ d.profit < 0?'subtract':'add' ]">{{ d.profit > 0?'+':'' }}{{ d.profit }}</p>
		  				<p class="explain">{{ d.name }}</p>
		  			</li>
		  		</ul>
		  		<div class="surplus">{{ det.Dprofit }}</div>
		  	</dd>
		  </dl>
		  
		</van-list>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, List, Toast } from 'vant'
	Vue.use(NavBar)
	   .use(List)
	   .use(Toast);
	
	import { profitDetail } from '@/js/axios'
	
	export default{
		name: "agentEarningsDetail",
		data() {
			return {
				list: [],
				loading: false,
				finished: false,
				
				pager: {// 默认一页
					page: 1,// 默认第一页
					pagesize: 1,// 每页1个月
				},
			}
		},
		methods: {
			onLoad() {
				profitDetail(this.pager).then(res => {
					// console.log(res)
					if(res.code == 200){
						var data = res.data.data;
						this.pager.page++;
						
						// 当前月份是否为空
						if(data.length > 0){
							this.list = this.list.concat(data);
							this.loading = false;
						}else{
							// 数据为空，请求下一页
							if(res.data.isLastPage != 1){
								this.onLoad();
							}
						}
						
						if(res.data.isLastPage == 1){
							this.loading = false;
							this.finished = true;
						}
						
					}else{
						Toast.fail(res.msg);
					}
					
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.detail{
		color: #3B4549;
		
		&>div,
		dt,
		dd{
			padding: 0 0.32rem;
		}
		
		.notice{
			padding-top: 0.2rem;
			padding-bottom: 0.2rem;
			line-height: 0.38rem;
			background-color: #EBEDF5;
		}
		
		.van-list{
			padding: 0;
		}
		
		.tit,
		dt{
			line-height: 0.59rem;
		}
		.tit,
		dd{
			display: flex;
			justify-content: space-between;
			align-items: center;
			
			.date{
				width: 26%;
			}
			.cont{
				width: 61%;
				
				li:not(:last-child){
					margin-bottom: 0.32rem;
				}
			}
			.surplus{
				width: 13%;
			}
		}
		
		.tit{
			color: #5D6466;
			background-color: #D6D9E6;
		}
		
		dl{
			color: #8C9699;
			
			dt{
				background-color: #E4E6EF;
			}
			dd{
				padding-top: 0.18rem;
				padding-bottom: 0.24rem;
				
				&:not(:first-child){
					border-bottom: 0.01rem solid #E1E3EB;
				}
				.surplus{
					color: #3B4549;
				}
				
				p{
					padding-left: 0.4rem;
					background-repeat: no-repeat;
					background-size: 0.28rem 0.28rem;
					background-position: left center;
					line-height: 0.3rem;
					
					&:not(:last-child){
						margin-bottom: 0.08rem;
					}
					
					&.add{
						color: #6883FB;
						background-image: url(../../../assets/images/agent_earnings_add.png);
					}
					&.subtract{
						color: #55A14D;
						background-image: url(../../../assets/images/agent_earnings_subtract.png);
					}
				}
			}
		}
		
	}
</style>
